<template>
  <div class="color-border">
    <div class="color-block" :class="classes" :style="{ backgroundColor: colorHash }">
      <close-outlined class="icon-undefined"/>
      <check-outlined class="icon-selected"/>
    </div>
  </div>
</template>

<script>
import { CloseOutlined, CheckOutlined } from '@ant-design/icons-vue';
import { computed } from "@vue/reactivity";

export default {
  name: 'ColorView',
  props: {
    color: String,
    size: { type: String, default: 'medium' }, // small | medium | large
    selected: Boolean,
  },
  setup(props) {

    const classes = computed(() => {
      const classesArr = [];
      classesArr.push('size-' + props.size);
      if (props.color === 'FFFFFF' || !props.color) classesArr.push('undefined');
      else classesArr.push('regular');

      if (props.selected) classesArr.push('selected');
      return classesArr;
    });

    const colorHash = computed(() => '#' + props.color);

    return { classes, colorHash }
  },
  components: { CloseOutlined, CheckOutlined },
}
</script>

<style lang="less" scoped>
.color-border {
  display: inline-block;
  background-color: @background-primary;
  border-radius: @border-radius-base;
  padding: 0.5rem;
  font-size: 0;
  line-height: normal;
  vertical-align: top;

  .color-block {
    display: inline-block;
    border: 2px solid transparent;
    text-align: center;
    background-color: white;
    vertical-align: top;
    position: relative;
    border-radius: 2px;
    transition: 0.2s ease-in-out;

    &:active {
      opacity: 0.9;
    }

    .anticon {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.1s ease-in-out;
    }

    &.size-small {
      width: 1rem;
      height: 1rem;
      font-size: 0.75rem;
    }

    &.size-medium {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1rem;
    }

    &.size-large {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }

    &.regular {
      .anticon {
        color: white;
      }

      &.selected {
        .icon-selected {
          opacity: 1;
        }
      }
    }

    &.undefined {
      border-color: @disabled-color;

      .anticon {
        color: @disabled-color;
      }

      .icon-undefined {
        opacity: 1;
      }

      &.selected {
        .icon-selected {
          opacity: 1;
        }

        .icon-undefined {
          opacity: 0;
        }
      }
    }
  }
}
</style>
