import Axios from "axios";
import { reactive, ref } from "@vue/reactivity";
import { store } from '@/store'
import { urlHelper } from "@/compositions/commonFunctions";
import { unref } from "@vue/runtime-core";

// API запросы
export function requestAPI() {

  // Получение списка типов
  async function fetchListTypes(params) {
    console.log('/object-types fetchListTypes',params);
    try {
      return await Axios.get(`/object-types` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение единичного объекта
  async function getType(id) {
    try {
      return await Axios.get(`/object-types/${id}`);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Создание типа объектов / Обновление типа объектов по ID
  async function updateType(formData, id = null, params) {
    try {
      if (id) {
        return await Axios.put(`/object-types/${id}` + urlHelper.makeGetParams(params), { ...formData });
      } else {
        return await Axios.post(`/object-types` + urlHelper.makeGetParams(params), { ...formData });
      }
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Удаление типа объектов по ID
  async function removeType(id, params) {
    if (!id) return false;
    try {
      return await Axios.post(`/object-types/${id}/delete`, params);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  return { fetchListTypes, getType, updateType, removeType }
}

export function getListData() {
  const loading = ref(false);

  const columns = [
    {
      title: 'Превью',
      width: 100,
      slots: { customRender: 'previewImageUrl' },
    },
    {
      title: 'Цвет идентификатора',
      width: 200,
      slots: { customRender: 'color' },
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      slots: { customRender: 'name' },
    },
    {
      title: 'Политика доступов',
      sorter: true,
      dataIndex: 'sortReservationPolicyBy',
      key: 'sortReservationPolicyBy',
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      slots: { customRender: 'reservationPolicyName' },
    },
    {
      className: 'text-right',
      slots: { customRender: 'actions', title: 'actionsTitle' },
    },
  ];
  const data = ref([]);
  const search = ref('');
  const withPermission = ref(false);
  const pagination = reactive({ hideOnSinglePage: true });
  const orderBy = reactive({ sortReservationPolicyBy: 'ascend' });
  const isFilterSelected = ref(false);

  async function handleTableChange(tablePagination, filters, sorter) {
    console.log(filters);
    console.log(sorter);
    pagination.current = tablePagination.current;
    if (sorter) {
      orderBy[sorter.field] = sorter.order;
    }
    await fetchList();
  }

  async function fetchList() {
    try {
      loading.value = true;
      const res = await requestAPI().fetchListTypes({
        page: pagination.current || 1,
        search: encodeURI(unref(search)),
        sortReservationPolicyBy: orderBy.sortReservationPolicyBy,
        withPermission: unref(withPermission),
      });
      //
      data.value = res.data;
      pagination.pageSize = +res.meta.perPage;
      pagination.total = +res.meta.total;
      pagination.current = +res.meta.currentPage;
      isFilterSelected.value = !!unref(search);
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  // Если будут фильтры, тут они будут применяться и сбрасываться
  async function applyFilters() {
    await fetchList();
  }

  async function resetFilters() {
    search.value = '';
    isFilterSelected.value = false;
    await applyFilters();
  }

  return {
    loading,
    columns, data, search, pagination, withPermission,
    isFilterSelected,
    fetchList,
    handleTableChange,
    applyFilters,
    resetFilters
  };
}

export function getOneData() {
  const formInfo = ref({
    name: undefined,
    idColor: 'FFFFFF',
    reservationPolicyId: undefined,
    reservationAvailable: false,
    userInvitationAvailable: false,
    previewUrl: undefined,
  });

  const formAttributes = ref({ attributes: [] });

  return { formInfo, formAttributes };
}
