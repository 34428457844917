<template>
  <span class="ant-search-expandable" :class="{ expanded }" @click="expand">
    <span class="clear-text" :style="{ opacity: stringValue ? 1 : 0 }">
      <close-circle-filled @click="clearText"/>
    </span>
    <a-input-search
        v-model:value="stringValue" @blur="handlerBlur"
        :placeholder="placeholder"
        :size="size || 'large'"
        @search="handleEnter"
    >
    </a-input-search>
  </span>
</template>

<script>
import { nextTick } from "@vue/runtime-core";
import { computed, ref, unref } from "@vue/reactivity";
import {
  CloseCircleFilled
} from '@ant-design/icons-vue';

export default {
  name: 'SearchExpandable',
  props: {
    modelValue: String,
    placeholder: String,
    size: String,
  },
  setup(props, { emit }) {

    const expanded = ref(false);

    const stringValue = computed({
      get: () => props.modelValue,
      set: async (newValue) => emit('update:modelValue', newValue)
    });

    function expand() {
      nextTick(() => {
        expanded.value = true;
      });
    }

    function handlerBlur() {
      if (!unref(stringValue)) {
        expanded.value = false;
      }
    }

    function clearText() {
      stringValue.value = '';
      nextTick(() => {
        emit('enter', '');
      });
    }

    function handleEnter() {
      if (expanded.value) {
        emit('enter', unref(stringValue));
      }
    }

    return { stringValue, expanded, expand, clearText, handlerBlur, handleEnter }
  },
  components: { CloseCircleFilled }
}
</script>

<style lang="less">
.ant-search-expandable {
  position: relative;
  display: inline-block;
  max-width: 100%;
  border: 1px solid @disabled-color;
  border-radius: @border-radius-base;
  transition: 0.2s ease-in-out;

  .ant-input-search {
    padding: 4px 8px;
    height: 38px;
    line-height: 38px;
    min-width: 38px;
    background-color: transparent;
    border: none;

    .ant-input-suffix {
      margin: 0 2px 0 2px;
    }
  }

  .clear-text {
    display: none;
    transition: 0.2s ease-in-out;
    position: absolute;
    right: 40px;
    top: 7px;
    color: @disabled-color;
    cursor: pointer;
    z-index: 4;

    &:hover {
      color: @text-color;
    }
  }

  .ant-input {
    width: 0;
    background-color: transparent;
  }

  &.expanded {
    border-color: @text-color;

    .clear-text {
      display: block;
    }

    .ant-input {
      width: 400px;
      max-width: 100%;
      padding-right: 40px !important;
    }
  }
}
</style>
