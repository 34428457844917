<template>
  <section>
    <a-page-header title="Типы объектов">
      <template #extra>
        <search-expandable size="large" placeholder="Поиск по названию" v-model="search"
                           @enter="handleSearch"/>
        <a-button size="large" type="primary" @click="openModalAdd">
          Создать тип объекта
        </a-button>
      </template>
    </a-page-header>

    <main>
      <a-table :columns="columns" :data-source="data" row-key="id" :pagination="pagination"
               :locale="{ emptyText: isFilterSelected ? 'Ничего не найдено' : 'Нет данных' }" :loading="loading"
               @change="handleTableChange">
        <template #previewImageUrl="{ record }">
          <img :src="record.previewUrl" :alt="record.name" width="88" v-if="record.previewUrl">
        </template>
        <template #color="{ record }">
          <color-view :color="record.idColor ? record.idColor : 'FFFFFF'" size="large"/>
        </template>
        <template #name="{ record, text }">
          <router-link class="text-color-text" :to="{ name: 'typeView', params: { id: record.id } }">{{
              text
            }}
          </router-link>
        </template>
        <template #reservationPolicyName="{ record }">
          {{ record?.reservationPolicy?.name || '&mdash;' }}
        </template>
        <template #actions="{ record }">
          <a-button key="edit" type="dashed" size="large"
                    @click="() => $router.push({name: 'typeView', params: { id: record.id }})">
            <template #icon>
              <edit-outlined/>
            </template>
          </a-button>
          <a-button key="delete" type="dashed" size="large" @click="handleDelete(record)">
            <template #icon>
              <delete-outlined/>
            </template>
          </a-button>
        </template>
      </a-table>

      <!-- Создание нового типа объекта -->
      <a-modal v-model:visible="modalAddShow" title="Создать тип объекта" @ok="modalAddShow = false">
        <a-form
            ref="refForm"
            :model="formInfo"
            layout="vertical"
        >
          <a-form-item label="Доступен для бронирования" class="form-item-switch">
            <a-switch v-model:checked="formInfo.reservationAvailable"/>
          </a-form-item>
          <a-form-item label="Название *" name="name" :rules="[
            { required: true, message: 'Введите название типа', trigger: 'blur' },
            { min: 1, max: 255, message: 'Длина должна быть от 1 до 255 символов', trigger: 'change' }
          ]">
            <a-input v-model:value="formInfo.name" placeholder="Введите название"/>
          </a-form-item>
          <a-form-item label="Политика доступов *" name="reservationPolicyId" :rules="[
            { required: true, message: 'Выберите политику доступов', trigger: 'blur' },
          ]">
            <a-select v-model:value="formInfo.reservationPolicyId"
                      show-search
                      :default-active-first-option="false"
                      :show-arrow="true"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="getPolitics"
                      placeholder="Выберите политику" size="large">
              <a-select-option :value="option.id" v-for="(option) in politicsList" :key="option.id">
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" size="large" @click="modalAddShow = false">Отменить</a-button>
          <a-button key="submit" size="large" type="primary" :loading="modalAddLoading"
                    :disabled="!formInfo.name || !formInfo.reservationPolicyId"
                    @click="submitAdd">
            Сохранить
          </a-button>
        </template>
      </a-modal>

      <object-type-delete-busy-modal
          v-model:visible="isDeletingBusyType" :object-type="deletingBusyType"
          @proceed="handleDeleteAndRewrite"/>

    </main>
  </section>
</template>

<script>
import { ref, unref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  DeleteOutlined, EditOutlined,
} from '@ant-design/icons-vue';
import SearchExpandable from "@/components/common/SearchExpandable";
import ColorView from "@/components/admin/ColorView";
import { message, Modal, notification } from "ant-design-vue";
import { requestAPI, getListData, getOneData } from "@/compositions/objectTypes";
import { FETCH_RESERVATION_POLICIES } from "@/store/politics";
import ObjectTypeDeleteBusyModal from "../../../components/admin/objectTypes/ObjectTypeDeleteBusyModal";

export default {
  name: "TypesListPage",
  setup() {
    const store = useStore();
    const router = useRouter();

    const modalAddShow = ref(false);
    const modalAddLoading = ref(false);
    const politicsList = ref([]);

    const refForm = ref({});
    const { formInfo } = getOneData();

    const {
      loading,
      fetchList,
      columns, data, pagination, search, isFilterSelected,
      handleTableChange
    } = getListData();

    const { updateType, removeType } = requestAPI();

    const isDeletingBusyType = ref(false);
    const deletingBusyType = ref(null);

    onMounted(async () => {
      await fetchList();
      await getPolitics();
    });

    // Поиск политик с задержкой
    async function getPolitics(search) {
      const res = await store.dispatch(FETCH_RESERVATION_POLICIES, { search });
      politicsList.value = res?.data || [];
    }

    async function handleSearch() {
      pagination.current = 1;
      await fetchList();
    }

    async function handleDelete(record) {
      Modal.confirm({
        title: 'Удалить тип объекта?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        width: 400,
        async onOk() {
          const res = await removeType(record.id);
          if (res?.id) {
            notification.success({
              message: 'Успех',
              description: 'Успешно удалено',
            });
            await fetchList();
          } else {
            deletingBusyType.value = record;
            isDeletingBusyType.value = true;
          }
        },
      });
    }

    async function handleDeleteAndRewrite(replaceObjectTypeId) {
      isDeletingBusyType.value = false;
      try {
        const res = await removeType(unref(deletingBusyType)?.id, { replaceObjectTypeId });
        if (res?.id) {
          notification.success({
            message: 'Успех',
            description: 'Успешно удалено',
          });
          await fetchList();
        } else {
          notification.error({
            message: 'Ошибка удаления',
            description: 'Удаление не удалось',
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function openModalAdd() {
      modalAddShow.value = true;
    }

    async function submitAdd() {
      return refForm.value.validate()
          .then(async () => {
            modalAddLoading.value = true;
            const requestForm = unref(formInfo);
            const res = await updateType(requestForm);
            if (res?.id) {
              router.push({ name: 'typeView', params: { id: res?.id } });
            } else {
              throw TypeError('Ошибка сохранения типа');
            }
          })
          .catch(error => {
            if (error?.errorFields) {
              error.errorFields.forEach(errorField => errorField.errors.forEach(errorType => message.error(errorType)));
            } else {
              console.error('Ошибка отправки данных');
            }
          })
          .finally(() => {
            modalAddLoading.value = false;
          });
    }

    watch(modalAddShow, (close, open) => {
      // Если закрываем, очищаем форму
      if (close && !open) {
        try {
          getPolitics();
          formInfo.value.reservationAvailable = false;
          refForm.value.resetFields();
        } catch (e) {
          console.error(e);
        }
      }
    })

    return {
      loading,
      formInfo, refForm, fetchList, handleSearch, isFilterSelected,
      columns, data, pagination, search, modalAddShow, modalAddLoading,
      politicsList, getPolitics,
      handleTableChange, handleDelete, handleDeleteAndRewrite, submitAdd, openModalAdd,
      deletingBusyType, isDeletingBusyType,
    }
  },
  components: {
    ObjectTypeDeleteBusyModal,
    ColorView, SearchExpandable,
    DeleteOutlined, EditOutlined
  }
}
</script>
