<template>
  <a-modal v-model:visible="isVisible" :title="`Есть объекты с типом &laquo;${objectType?.name}&raquo;`"
           @ok="isVisible = false">

    <a-form layout="vertical">
      <a-form-item label="Привяжите их к другому типу">
        <a-select v-model:value="objectTypeId"
                  show-search class="w-100"
                  :default-active-first-option="false"
                  :show-arrow="true" :loading="loading"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="getObjectTypes"
                  placeholder="Введите текст для поиска" size="large">
          <a-select-option :value="option.id" v-for="(option) in objectTypesData" :key="option.id"
                           :disabled="option.id === objectType?.id">
            {{ option.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" size="large" @click="isVisible = false">Отменить</a-button>
      <a-button key="submit" size="large" type="primary" :loading="modalMoveLoading"
                :disabled="!objectTypeId" @click="handleSubmit">
        Привязать и удалить тип
      </a-button>
    </template>

  </a-modal>
</template>

<script>

import { computed, ref, unref } from "@vue/reactivity";
import { getListData as getObjectTypesData } from "@/compositions/objectTypes";
import { onMounted, watch } from "@vue/runtime-core";

export default {
  name: 'ObjectTypeDeleteBusyModal',
  props: {
    visible: Boolean,
    objectType: Object,
  },
  emits: ['proceed', 'update:visible'],
  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.visible,
      set: (value) => emit('update:visible', value)
    })

    const modalMoveLoading = ref(false);
    const objectTypeId = ref(null);

    const {
      loading,
      data: objectTypesData,
      search: objectTypesSearch,
      fetchList: fetchObjectTypes
    } = getObjectTypesData();

    onMounted(async () => {
      // await fetchObjectTypes();
    });

    watch(isVisible, (newVal, oldVal) => {
      if (newVal && !oldVal) {
        objectTypeId.value = null;
        fetchObjectTypes();
      }
    });

    async function getObjectTypes(search) {
      objectTypesSearch.value = search;
      fetchObjectTypes();
    }

    function handleSubmit() {
      if (unref(objectTypeId)) {
        emit('proceed', unref(objectTypeId));
      }
    }

    return {
      isVisible, loading, objectTypesData, objectTypeId, modalMoveLoading, getObjectTypes, handleSubmit
    }
  },
}
</script>
